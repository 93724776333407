import { Injectable } from '@angular/core';
import { UsersRolesPermissionsRequest } from '@app/shared/interfaces/users-roles-premissions/users-roles-permissions.request';
import { UsersRolesPermissions } from '@app/shared/interfaces/users-roles-premissions/users-roles-permissions.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';
import { AbstractHelper } from '../../abstract/abstract-helper.class';
import { HttpUpdateMethods } from '../../abstract/abstract.service';

@Injectable()
export class UsersRolesPermissionService extends AbstractCrudService<
  UsersRolesPermissions,
  UsersRolesPermissionsRequest
> {
  protected override feature = 'users-roles-permissions';
  protected override endPoint = `/users-roles-permissions/`;

  updateBulk(permissionsModel: UsersRolesPermissionsRequest): AbstractHelper<UsersRolesPermissions> {
    const endpoint = `${this.endPoint}*/bulk`;
    return this.update(HttpUpdateMethods.PUT, '', permissionsModel, [], endpoint);
  }
}
